<div class="app-container">
  <mat-toolbar color="primary" class="app-header">
    <button mat-icon-button (click)="snav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
    <h1 class="example-app-name">BusTrip Admin</h1>
    <span class="flex-spacer"></span>
    <button
      matBadge="2"
      matBadgeColor="warn"
      type="button"
      mat-icon-button
      class="me-3"
    >
      <mat-icon>notifications</mat-icon>
    </button>
    <button type="button" mat-icon-button [matMenuTriggerFor]="menu">
      <mat-icon>account_circle</mat-icon>
    </button>
  </mat-toolbar>

  <mat-sidenav-container class="sidenav-container">
    <mat-sidenav #snav mode="side" opened class="sidenav">
      <mat-nav-list>
        <mat-list-item routerLink="dashboard" routerLinkActive="active">
          <mat-icon mat-list-icon>dashboard</mat-icon>
          <div mat-line>Панель керування</div>
        </mat-list-item>
        <mat-list-item routerLink="trip" routerLinkActive="active">
          <mat-icon mat-list-icon>date_range</mat-icon>
          <div mat-line>Поїздки</div>
        </mat-list-item>
        <mat-list-item routerLink="driver" routerLinkActive="active">
          <mat-icon mat-list-icon>people</mat-icon>
          <div mat-line>Водії</div>
        </mat-list-item>
        <mat-list-item routerLink="bus" routerLinkActive="active">
          <mat-icon mat-list-icon>directions_bus</mat-icon>
          <div mat-line>Автобуси</div>
        </mat-list-item>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>

<mat-menu #menu="matMenu">
  <button mat-menu-item>
    <mat-icon>person</mat-icon>
    <span>Personal info</span>
  </button>
  <button mat-menu-item>
    <mat-icon>settings</mat-icon>
    <span>Settings</span>
  </button>
  <button mat-menu-item (click)="logOut()">
    <mat-icon>logout</mat-icon>
    <span>Logout</span>
  </button>
</mat-menu>
