import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { SideMenuComponent } from './side-menu/side-menu.component';

const routes: Routes = [
  {
    path: 'login',
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./login/login.module').then((m) => m.LoginModule),
  },
  {
    component: SideMenuComponent,
    path: '',
    canActivateChild: [AuthGuard],
    children: [
      {
        path: 'bus',
        loadChildren: () => import('./bus/bus.module').then((m) => m.BusModule),
      },
      {
        path: 'driver',
        loadChildren: () =>
          import('./driver/driver.module').then((m) => m.DriverModule),
      },
      {
        path: 'dashboard',
        loadChildren: () =>
          import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'trip',
        loadChildren: () =>
          import('./trip/trip.module').then((m) => m.TripModule),
      },
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    ],
  },
  {
    path: '**',
    loadChildren: () =>
      import('./page-not-found/page-not-found.module').then(
        (m) => m.PageNotFoundModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
