import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss'],
})
export class SideMenuComponent {
  constructor(private authService: AuthService, private router: Router) {}

  logOut() {
    this.authService.logOut().subscribe(() => this.router.navigate(['/login']));
  }
}
