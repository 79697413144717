import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor() {}

  logIn(): Observable<any> {
    localStorage.setItem('jwt-token', 'true');
    return of(null);
  }

  logOut(): Observable<any> {
    localStorage.removeItem('jwt-token');
    return of(null);
  }

  isLoggedIn(): Promise<boolean> {
    const token = localStorage.getItem('jwt-token');
    return Promise.resolve(!!token);
  }
}
